exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-analyza-index-tsx": () => import("./../../../src/pages/analyza/index.tsx" /* webpackChunkName: "component---src-pages-analyza-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cenik-index-tsx": () => import("./../../../src/pages/cenik/index.tsx" /* webpackChunkName: "component---src-pages-cenik-index-tsx" */),
  "component---src-pages-footer-index-tsx": () => import("./../../../src/pages/footer/index.tsx" /* webpackChunkName: "component---src-pages-footer-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-klienti-index-tsx": () => import("./../../../src/pages/klienti/index.tsx" /* webpackChunkName: "component---src-pages-klienti-index-tsx" */),
  "component---src-pages-landing-index-tsx": () => import("./../../../src/pages/landing/index.tsx" /* webpackChunkName: "component---src-pages-landing-index-tsx" */),
  "component---src-pages-sluzby-index-tsx": () => import("./../../../src/pages/sluzby/index.tsx" /* webpackChunkName: "component---src-pages-sluzby-index-tsx" */),
  "component---src-pages-tym-index-tsx": () => import("./../../../src/pages/tym/index.tsx" /* webpackChunkName: "component---src-pages-tym-index-tsx" */)
}

